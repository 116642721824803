import { combineReducers } from 'redux';

// import Auth from './auth/reducers';
import useAuth from './useAuth/reducer';
import Layout from './layout/reducers';
import PageTitle from './pageTitle/reducers';

export default combineReducers({
    useAuth,
    Layout,
    PageTitle,
});
