// import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// interface AuthState {
//     loggedIn: boolean;
//     data: any; // Data type can be more specific based on your need.
// }

// const initialState: AuthState = {
//     loggedIn: false,
//     data: null,
// };

// const authSlice = createSlice({
//     name: 'auth',
//     initialState,
//     reducers: {
//         login: (state, action: PayloadAction<any>) => {
//             state.loggedIn = true;
//             state.data = action.payload;
//         },
//         logout: (state) => {
//             state.loggedIn = false;
//             state.data = null;
//         },
//     },
// });

// export const { login, logout } = authSlice.actions;
// export default authSlice.reducer;

// authSlice.ts

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: null,
    isAuthenticated: false,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            state.user = action.payload;
            state.isAuthenticated = true;
        },
        logout: (state) => {
            state.user = null;
            state.isAuthenticated = false;
        },
        // ... other reducers
    },
});

export const { loginSuccess, logout } = authSlice.actions;
export default authSlice.reducer;
