import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import Layout from '../layout/reducers';
import PageTitle from '../pageTitle/reducers';

const rootReducer = combineReducers({
    auth: authReducer,
    Layout,
    PageTitle,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
