import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';

// import { Provider } from 'react-redux';
//
// import toastify for notification
import { Slide, ToastContainer } from 'react-toastify';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/useAuth/store';
const queryClient = new QueryClient();

ReactDOM.render(
    // <Provider store={configureStore({})}>

    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Provider store={configureStore({})}>
                <QueryClientProvider client={queryClient}>
                    <ToastContainer transition={Slide} newestOnTop />
                    <App />
                </QueryClientProvider>
            </Provider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
